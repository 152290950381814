@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&amp;display=swap');

:root {
    --base-width: 340px;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Inter";
}

html, body, div#root, div.App {
    width: 100%;
    height: 100%;
}

.home-btn {
    background-color: #000000;
    border-radius: 30px;
    color: #ffffff;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 600;
    border: 0;
    padding: 16px;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.cancel-btn {
    background-color: #ffffff;
    border-radius: 30px;
    color: #000000;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 600;
    border: 2px solid #000000;
    padding: 16px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    margin-top: 5px;
}

.homepage-wrapper {
    width: 100%;
    padding: 0 20px;
}

.homepage-wrapper .homepage-container {
    width: 100%;
    max-width: var(--base-width);
    margin: 0 auto;
    overflow: hidden;
}

.homepage-wrapper .homepage-container .homepage-inner-container {
    display: flex;
    transition: all 0.5s ease-in-out;
    transform: translate(0px, 0px);
}

.homepage-wrapper .homepage-container .homepage-inner-container.page1 {
    transform: translate(0px, 0px);
}

.homepage-wrapper .homepage-container .homepage-inner-container.page2 {
    transform: translate(-340px, 0px);
}

.homepage-wrapper .homepage-container .homepage-inner-container.page3 {
    transform: translate(-680px, 0px);
}

.homepage-wrapper .homepage-container .homepage-inner-container.page4 {
    transform: translate(-1020px, 0px);
}


.homepage-wrapper .homepage-container .homepage-welcome {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.homepage-wrapper .homepage-container .homepage-welcome.page-item {
    min-width: var(--base-width);
}

.homepage-wrapper .homepage-container .homepage-welcome .homepage-img {
    width: 120px;
    margin-bottom: 32px;
}

.homepage-wrapper .homepage-container .homepage-welcome.screen-one .homepage-img {
    width: 250px;
}

.homepage-wrapper .homepage-container .homepage-earphone .homepage-img {
    width: 219px;
}

.homepage-wrapper .homepage-container .homepage-quiet-place .homepage-img {
    width: 292px;
}

.homepage-wrapper .homepage-container .homepage-microphone-permission .homepage-img {
    width: 253px;
}

.page-item.end-page .homepage-img {
    width: 70px !important;
}

.homepage-wrapper .homepage-container .homepage-welcome .homepage-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.homepage-wrapper .homepage-container .homepage-welcome .homepage-title {
    font-size: 25px;
    font-weight: bold;
    line-height: normal;
    margin-bottom: 25px;
    color: #000000;
    text-align: center;
}

.homepage-wrapper .homepage-container .homepage-welcome .homepage-desc {
    font-size: 17px;
    line-height: normal;
    margin-bottom: 50px;
    color: #000000;
    text-align: center;
}

.homepage-wrapper .homepage-container .homepage-welcome .homepage-bullets {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-bottom: 32px;
}

.homepage-wrapper .homepage-container .homepage-welcome .homepage-bullets span {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #e0e0e0;
}

.homepage-wrapper .homepage-container .homepage-welcome .homepage-bullets span.active {
    background-color: #000000;
}

.homepage-wrapper .homepage-container .homepage-welcome .homepage-btn-wrapper {
    width: 100%;
}


.taptobegin-wrapper {
    /*width: calc(100% - 30px);*/
    width: 100%;
    height: 100%;
    /*padding: 0 15px;*/

}

.taptobegin-wrapper .taptobegin-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

/*
.taptobegin-wrapper .taptobegin-container .middle-gradient {
    width: 100%;
    height: 100%;
    background-image: url(../images/circle-gradient.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
} */
.taptobegin-wrapper .taptobegin-container .middle-gradient {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    display: flex; /* Use flexbox to center content */
    justify-content: center; /* Horizontally center the video */
    align-items: center; /* Vertically center the video */
}

.taptobegin-wrapper .taptobegin-container .background-video {
    /* position: absolute;
     top: 50%;
     left: 50%;*/
    width: 100%;
    height: auto;
    max-width: 500px;
    object-fit: cover;
    /*transform: translate(-50%, -50%);*/
    z-index: -1;
}

.taptobegin-wrapper .taptobegin-container .fallback-image {
    /*position: absolute;
    top: 50%;
    left: 50%;*/
    width: 100%;
    height: 100%;
    object-fit: cover;
    /*transform: translate(-50%, -50%);*/
    z-index: -1;
}

.taptobegin-wrapper .taptobegin-container .gradient-animation {
    z-index: 1; /* Ensure other elements are above the video */
}


.taptobegin-wrapper .taptobegin-container .bottom-speaking-text-area {
    /* position: absolute;
     bottom: 104px;*/
    width: calc(100% - 40px);
    padding: 0 20px;
    color: rgba(0, 0, 0, 0.33);
    text-align: center;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-top: 120px;
}

.taptobegin-wrapper .taptobegin-container .audio-wave-wrapper {
    /* position: absolute;
     bottom: 50px;*/
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 120px;
}

.taptobegin-wrapper .taptobegin-container .audio-wave-wrapper .sound-wave-loader div {
    background-color: #7bc5fa;
}

.taptobegin-wrapper .taptobegin-container .audio-wave-wrapper.user-wave .sound-wave-loader div {
    background-color: #bfbfbf;
}


.sound-wave-bars {
    display: flex;
    height: 40px;
    width: 100%;
    align-items: center;
    justify-content: center;
    max-width: 320px;
}

.bar {
    height: 20px;
    width: 4px;
    background-color: #7ad9d3;
    border-radius: 4px;
    margin: 0 2px;
    animation: largeWaveAnim ease-in-out infinite alternate;
}

.listening .bar {
    background: #BFBFBF;
}

@keyframes largeWaveAnim {
    0% {
        height: 30px;
        opacity: 0.33;
    }

    100% {
        height: 40px;
        opacity: 1;
    }
}

.bar:nth-last-child(-n+15) {
    background-color: #89d5cb;
    animation-name: mediumWaveAnim;
}

.bar:nth-last-child(-n+35) {
    background-color: #78cee7;
    animation-name: mediumWaveAnim;
}

.listening .bar:nth-last-child(-n+15) {
    background: #BFBFBF;
}

.listening .bar:nth-last-child(-n+35) {
    background: #BFBFBF;
}

@keyframes mediumWaveAnim {
    0% {
        height: 30px;
        opacity: 0.33;
    }

    100% {
        height: 45px;
        opacity: 1;
    }
}

.bar:nth-last-child(-n+25) {
    background-color: #70cae5;
    animation-name: smallWaveAnim;
}

@keyframes smallWaveAnim {
    0% {
        height: 20px;
        opacity: 0.33;
    }

    100% {
        height: 50px;
        opacity: 1;
    }
}

.homepage-welcome-content-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


@media only screen and (max-width: 480px) {
    :root {
        --base-width: 100%;
    }

    .homepage-wrapper {
        padding: 0;
    }

    .homepage-wrapper .homepage-container .homepage-welcome.page-item {
        min-width: calc(var(--base-width) - 30px);
        padding: 0 15px;
    }

    .homepage-wrapper .homepage-container .homepage-inner-container.page2 {
        transform: translate(-100%, 0px);
    }

    .homepage-wrapper .homepage-container .homepage-inner-container.page3 {
        transform: translate(-200%, 0px);
    }

    .homepage-wrapper .homepage-container .homepage-inner-container.page4 {
        transform: translate(-300%, 0px);
    }

    .taptobegin-wrapper .taptobegin-container .audio-wave-wrapper canvas {
        width: 180px;
        height: 100px;
    }

    .taptobegin-wrapper .taptobegin-container .background-video {
        max-width: 100%;
        margin-left: 120px;
        margin-right: 120px;
    }
}