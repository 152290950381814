/* src/Chat.css */
.landing-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* background-color: #f0f0f0; */
    background-color: #fff;
}

.option-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
    max-width: 600px;
    width: 100%;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.option-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #e0e0e0;
    border-radius: 10px;
    text-align: center;
    transition: background-color 0.3s;
    cursor: pointer;
}

.option-item:hover {
    background-color: #d0d0d0;
}

.option-item h3 {
    margin-top: 10px;
    font-size: 1.2em;
}


.chat-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.messages {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.message {
    margin-bottom: 15px;
    padding: 10px 16px;
    border-radius: 5px;
    width: fit-content;
    max-width: 70%;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.075), 0rem 1rem 1rem -1rem rgba(0, 0, 0, 0.1);
    font-size: 15px;
    line-height: 22px;
    color: #000000;
    border-radius: 18px;
}

.message.user {
    background-color: #e8ebfa;
    /* align-self: flex-end; */
    border-bottom-right-radius: 0;
}

.message.assistant {
    background-color: #f5f5f5;
    align-self: flex-start;
    border-bottom-left-radius: 0;
}

.input-container {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    background: #f0f0f0;
    gap: 15px;
}

.input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    border-radius: 20px;
    font-size: 15px;
    outline: none;
}

.send-button,
.voice-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 0;
}

.send-button:hover,
.voice-button:hover {
    color: #007bff;
}

.listen-text {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #555;
    padding-bottom: 10px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px; /* Adjust height as needed */
    margin-left: 40px;
}

.loader {
    display: inline-block;
    width: 4px;
    height: 24px;
    background-color: #007bff;
    position: relative;
    animation: loaderAnimation 1.5s ease-in-out infinite;
}

.loader::before,
.loader::after {
    content: '';
    position: absolute;
    top: 0;
    width: 4px;
    height: 24px;
    background-color: #007bff;
}

.loader::before {
    left: -8px;
    animation: loaderAnimation 1.5s ease-in-out infinite;
    animation-delay: 0.25s;
}

.loader::after {
    left: 8px;
    animation: loaderAnimation 1.5s ease-in-out infinite;
    animation-delay: 0.5s;
}

@keyframes loaderAnimation {
    0% {
        transform: scaleY(0.3);
    }
    50% {
        transform: scaleY(1);
    }
    100% {
        transform: scaleY(0.3);
    }
}


.dot-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
}

.avatar-container {
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #007bff;
    background-color: #bbd6f3;
}

.dot-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 30px;
    height: 10px;
    transform: translate(-50%, -50%);
}

.dot-loader div {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #7bc5fa;
    animation: dotLoaderAnimation 1.4s infinite ease-in-out both;
}

.dot-loader div:nth-child(1) {
    animation-delay: -0.32s;
}

.dot-loader.message-loader {
    position: relative;
}

.dot-loader div:nth-child(2) {
    animation-delay: -0.16s;
}

@keyframes dotLoaderAnimation {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}

span.loading-text {
    font-size: 8px;
    position: absolute;
    bottom: 33px;
    font-weight: bold;
    color: #555;
    margin-left: 0;
}

.sound-wave-loader {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 30px;
    width: 30px;
    right: 0;
    bottom: 0;
    margin: auto;
}

.sound-wave-loader div {
    width: 4px;
    height: 100%;
    margin: 0 2px;
    background-color: #7bc5fa;
    animation: soundWaveAnimation 1.2s infinite ease-in-out;
}

.sound-wave-loader div:nth-child(1) {
    animation-delay: -1.1s;
}

.sound-wave-loader div:nth-child(2) {
    animation-delay: -1s;
}

.sound-wave-loader div:nth-child(3) {
    animation-delay: -0.9s;
}

.sound-wave-loader div:nth-child(4) {
    animation-delay: -0.8s;
}

.sound-wave-loader div:nth-child(5) {
    animation-delay: -0.7s;
}

.end-container .end-icon img {
    width: 100px;
}

.end-container .end-text {
    font-size: 21px;
    font-weight: 500;
}

.end-container {
    text-align: center;
}

.end-container .card-data {
    margin-top: 20px;
}

.copy-button {
    background-color: #4CAF50; /* Green background */
    border: none; /* Remove borders */
    color: white; /* White text */
    padding: 15px 32px; /* Some padding */
    text-align: center; /* Centered text */
    text-decoration: none; /* Remove underline */
    display: inline-flex; /* Make the button inline-flex to use flexbox */
    align-items: center; /* Vertically center the content */
    justify-content: center; /* Center the content horizontally */
    font-size: 16px; /* Increase font size */
    margin: 4px 2px; /* Add some margin */
    cursor: pointer; /* Add a pointer on hover */
    border-radius: 12px; /* Rounded corners */
    transition: background-color 0.3s; /* Smooth transition for hover */
}

.copy-button img {
    margin-right: 8px; /* Add some space between the icon and text */
}

.copy-button:hover {
    background-color: #45a049; /* Darker green on hover */
}

.input-container.text-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.disable-click {
    pointer-events: none;
}

@media only screen and (max-width: 767px) {
    .message {
        max-width: 100%;
    }
}

@keyframes soundWaveAnimation {
    0%, 40%, 100% {
        transform: scaleY(0.4);
    }
    20% {
        transform: scaleY(1);
    }
}

.avatar-container.beat {
    animation: heartBeatAnimation 1.5s infinite ease-in-out;
}

@keyframes heartBeatAnimation {
    0%, 20%, 50%, 80%, 100% {
        transform: scale(1);
    }
    40% {
        transform: scale(1.2);
    }
    60% {
        transform: scale(1.1);
    }
}