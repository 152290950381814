/* LoginForm.css */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 20px;
  }
  
  .login-form {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    background: white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .login-title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .input-group input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .login-button {
    width: 100%;
    padding: 12px;
    background-color: #000000;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .login-button:hover {
    background-color: #333333;
  }

  /* LoginForm.css */
.error-message {
    color: red;
    font-size: 14px;
    margin: 10px 0;
  }
  
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .login-form {
      padding: 15px;
    }
  
    .login-title {
      font-size: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .login-form {
      width: 90%;
      padding: 10px;
    }
  
    .login-title {
      font-size: 18px;
    }
  }
  